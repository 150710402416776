import * as React from "react"
import Helmet from "react-helmet"
import {Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Row from "../../../components/row"

const VideoLibraryTwo = () => (
  <Layout>
	<Helmet>
        <script src={"/js/main.js"} type="text/javascript" />
    </Helmet>
    <Seo title="Video | Mechanisms of IBD" description="Watch to learn about the mechanisms of IBD" />
    <header className="headline">
	<img src={"/images/black-and-green-ibd-science-graphic-image.svg"} alt="ibd science gastroenterology" />
	</header>
	<section className="wrap"><h2 className="perfectSpace">Curated and continually updated content to advance the science and treatment of IBD</h2><br/>
	<header className="orange rule mystery-space"><h1 id="mechanisms-of-ibd">MECHANISMS OF IBD: <span>FOCUS ON S1P</span></h1></header>
		<div className="videoHolder"><iframe src="https://player.vimeo.com/video/668804061?h=a002c24028" width="1032" height="590" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe></div>
		
	</section>
    <section className="spacer end video"></section>
	<footer className="definitions">S1P=sphingosine-1-phosphate.
<br/>
<span>Arena is a clinical stage pharmaceutical company that does not have any medicines or products approved for use by any health authority.</span></footer>
  </Layout>
)

export default VideoLibraryTwo
